import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { postMethod, urls } from '../utils/api';
import { mycolors } from '../helpers/mycolors';
import { useNavigate } from 'react-router-dom';
import { resources } from '../helpers/resources';
import Loading from '../components/Loading';

function WordMemorizeScreen() {
    const location = useLocation();
    const usage = location.state?.usage || 1;
    const navigate = useNavigate();

    const [showMeanings, setShowMeanings] = useState(false);
    const [showMeaningsDemo, setShowMeaningsDemo] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [words, setWords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [doneWordIds, setDoneWordIds] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        checkShowDemo();
        // getDoneWordIds();
    }, []);

    const checkShowDemo = async () => {
        try {
            //await AsyncStorage.removeItem('showedWordMemorizeDemo');

            const _showedWordMemorizeDemo = localStorage.getItem('showedWordMemorizeDemo');
            if (_showedWordMemorizeDemo == null || _showedWordMemorizeDemo == undefined) {
                //setOpen(true);
                getDoneWordIds();
            }
            else {
                //setOpen(false);
                getDoneWordIds();
            }
        }
        catch {

        }
    }

    const getDoneWordIds = async () => {
        try {

            //await AsyncStorage.setItem('doneWordIds', '[]');

            const _doneWordIds = localStorage.getItem('doneWordIds');
            if (_doneWordIds == null || _doneWordIds == undefined) {
                localStorage.setItem('doneWordIds', '[]');
                setDoneWordIds([]);
                getWords([]);
            }
            else {
                const storedList = JSON.parse(_doneWordIds);
                setDoneWordIds(storedList);
                getWords(storedList);
            }

        } catch (error) {

        }
    };

    const getWords = async (_doneWordIds) => {
        try {
            const response = await postMethod(urls.get_words, { usage: usage });
            const newWords = response.filter(word => !_doneWordIds.some(doneWord => doneWord.id === word.id));
            // _doneWordIds.push(...newWords);
            //console.log(newWords.length)
            setWords(newWords);
            setLoading(false);
        } catch (error) {

            setLoading(false);
        }
    };


    const getAudio = async (word) => {
        try {
            const audioUrl = urls.api_url + urls.get_audio.replace('{soundKey}', word);
            const audio = new Audio(audioUrl);

            audio.play().catch((error) => {
                //console.error('Audio playback failed:', error);
            });
        } catch (error) {
            //console.error('Error in getAudio:', error);
        }
    };

    const renderShowMeanings = (word, meanings) => {
        return (
            <div style={{ display: 'flex', flex: 1, width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <span style={{ marginBottom: 20, color: mycolors.darkThemeGreen, fontSize: 30, textAlign: 'center', fontWeight: '700' }}>{word}</span>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {
                        meanings.map((item, index) => {
                            return <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} key={item.mean + item.meanType}>
                                <span style={{ color: mycolors.grey, fontSize: 15, textAlign: 'center', fontWeight: '500' }}>{item.mean} ({item.meanType})</span>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }

    if (loading) {
        return <Loading />
    }

    const renderOnlyWord = (word) => {
        return (
            <div style={{ display: 'flex', flex: 1, width: '90%', justifyContent: 'center' }}>
                <span style={{ color: mycolors.grey, fontSize: 30, textAlign: 'center', fontWeight: '700' }}>{word}</span>
            </div>
        )
    }

    const handleSkipButton = async () => {
        setShowMeanings(false);
        if (currentIndex + 1 >= words.length) {
            navigate('SuccessMemorizeScreen');
        }
        else {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
        }
    }

    const handleAddWordId = async (item, type) => {

        try {
            const _doneWordIds = localStorage.getItem('doneWordIds');
            let storedList = JSON.parse(_doneWordIds);

            const index = storedList.findIndex(obj => obj.id === item.id);

            const currentDate = new Date().toISOString().split('T')[0];

            const _today = new Date().toISOString().split('T')[0];
            const todayFilteredItems = storedList.filter(obj => obj.date === _today);

            const lastSevenDaysItems = storedList.filter(obj => {
                const itemDate = new Date(obj.date);
                const today = new Date();
                const diffTime = Math.abs(today - itemDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return diffDays <= 7;
            });

            const last1MonthItems = storedList.filter(obj => {
                const itemDate = new Date(obj.date);
                const today = new Date();
                const diffTime = Math.abs(today - itemDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return diffDays <= 30
            });

            if (index === -1) {
                storedList.push({ id: item.id, type: type, usage: usage, date: currentDate });
                localStorage.setItem('doneWordIds', JSON.stringify(storedList));
            }

            setShowMeanings(false);

            if (currentIndex + 1 >= words.length) {
                navigate('SuccessMemorizeScreen');
            }
            else {
                const newIndex = currentIndex + 1;
                setCurrentIndex(newIndex);
            }

        }
        catch (error) {
            //console.log(error)
        }
    }

    const renderCardView = (item) => {
        return (
            <div key={item.word} style={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: mycolors.backGroundColor, flexDirection: 'column', border: `1px solid ${mycolors.generalColor}`, borderRadius: 5 }}>
                    <div style={{ display: 'flex', height: 40, width: '100%', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                        <div style={{ height: 40, width: '50%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ flex: 1, justifyContent: 'center' }} onClick={() => { navigate('/seviyelerine-gore-ingilizce-kelimeler') }}>
                                <img src='/assets/images/back-arrow.png' style={{ cursor: 'pointer', height: 35, width: 35, marginLeft: 5 }} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', height: 40, width: '50%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div onClick={() => { getAudio(item.word) }} style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'flex-end' }}>
                                <img src='/assets/images/grammar-images/word-sound-icon.png' style={{ cursor: 'pointer', height: 35, width: 35, marginRight: 5 }} />
                            </div>
                        </div>
                    </div>

                    <div style={{ cursor: 'pointer', flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => { setShowMeanings(!showMeanings) }} >

                        {
                            showMeanings
                                ? renderShowMeanings(item.word, item.meanings)
                                : renderOnlyWord(item.word)
                        }

                    </div>

                </div>


                <div style={{ height: 200, width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 }}>
                    <div style={{ flex: 1, height: '100%', width: '85%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>

                        <div onClick={() => { handleSkipButton() }} style={{ cursor: 'pointer', height: 50, width: '100%', border: `2px solid ${mycolors.generalColor}`, borderRadius: 10, backgroundColor: mycolors.backGroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <img src='/assets/images/shield.png' style={{ height: 30, width: 30, marginRight: 10 }} />
                            <span style={{ fontSize: 18, fontWeight: '600', color: mycolors.darkThemeGreen }}>{resources.SkipForNow}</span>
                        </div>

                        <div onClick={() => handleAddWordId(item, 'ignore')} style={{ cursor: 'pointer', height: 50, width: '100%', border: `2px solid ${mycolors.generalColor}`, borderRadius: 10, backgroundColor: mycolors.backGroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <img src='/assets/images/check-icon.png' style={{ height: 30, width: 30, marginRight: 10 }} />
                            <span style={{ fontSize: 18, fontWeight: '600', color: mycolors.darkThemeGreen }}>{resources.IAlreadyKnow}</span>
                        </div>

                        <div onClick={() => handleAddWordId(item, 'memorize')} style={{ cursor: 'pointer', height: 50, width: '100%', border: `2px solid ${mycolors.generalColor}`, borderRadius: 10, backgroundColor: mycolors.darkThemeGreen, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <img src='/assets/images/crate.png' style={{ height: 30, width: 30, marginRight: 10 }} />
                            <span style={{ fontSize: 18, fontWeight: '600', color: mycolors.black }}>{resources.Memorize}</span>
                        </div>

                    </div>
                </div>
            </div >
        )
    }

    return (
        <div style={{ width: '100%', height: '100vh', backgroundColor: mycolors.backGroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

            <div style={{ height: 20, width: '100%' }}></div>
            {
                words.map((item, index) => (
                    currentIndex == index &&
                    renderCardView(item)
                ))
            }
            <div style={{ height: 20, width: '100%' }}></div>

        </div>
    );
}

export default WordMemorizeScreen;
