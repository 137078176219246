import { useEffect, useState } from "react";
import { mycolors } from "../helpers/mycolors";
import { useNavigate } from 'react-router-dom';
import TopMenuLayout from "../components/TopMenuLayout";

const TemporaryScreen3 = () => {

    const sentences = [
        'emotional speech',
        'share our memories',
        'unforgettable moment',
        'memorible experience',
        'if you are looking for a great deal,',
        'problem-solving skills',
        'guide me in my career path',
        'build strong connections',
        'in constrast, ...', 
        'XYZ hotel lacks these features',
        'there are several great ways to start',
        'back then, social media was becoming really popular',
        'memorable moment',
        'looking back, this experience was ...',
        'XXX has changed a lot since then',
        'floor-to-ceiling windows',
        'if we drive, we will only spend $90 each on gas, whereas a plane ticket costs $120 per person',
        "that's a huge difference",
        'he has different priorities',
        'financial loses for you',
        'as a language learner myself',
        'however, what really stands out is its nose - it is extremely large, long, and droppy, giving the animal very distinctive look',
        'unique appearance'
    ];

    const randomizedSentences = sentences.sort(() => Math.random() - 0.5);

    return (
        <div style={{ width: '100%', backgroundColor: mycolors.backGroundColor, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {randomizedSentences.map((sentence, index) => (
                <p style={{ color: mycolors.generalColor, fontSize: 14, fontWeight: '500' }} key={index}>{sentence}</p>
            ))}
        </div>
    )

}

export default TemporaryScreen3;