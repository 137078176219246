import React, { useEffect, useState } from "react";
import { mycolors } from "../helpers/mycolors";
import { resources } from "../helpers/resources";
import { urls } from "../utils/api";

const QuizTest = ({ data, handleNextButton, handlePreviousButton, currentIndex, listLength }) => {

    const [answerStatus, setAnswerStatus] = useState('show-default');
    const [isButtonValid, setIsButtonValid] = useState(false);

    const handleClickAnswer = (item) => {
        if (item.isAnswer) {
            setAnswerStatus('show-correct');
        }
        else {
            setAnswerStatus('show-incorrect');
        }
        setIsButtonValid(true);
    }

    const renderAnswers = () => {
        return (
            <>
                <div style={{ display: 'flex', height: 60, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={styles.answerContainer} onClick={() => handleClickAnswer(data.answers[0])}>
                        <span style={styles.answerText}>{data.answers[0].text}</span>
                    </div>
                    <div style={styles.answerContainer} onClick={() => handleClickAnswer(data.answers[1])}>
                        <span style={styles.answerText}>{data.answers[1].text}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', height: 60, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={styles.answerContainer} onClick={() => handleClickAnswer(data.answers[2])}>
                        <span style={styles.answerText}>{data.answers[2].text}</span>
                    </div>
                    <div style={styles.answerContainer} onClick={() => handleClickAnswer(data.answers[3])}>
                        <span style={styles.answerText}>{data.answers[3].text}</span>
                    </div>
                </div>
            </>
        )
    }

    const renderCorrectAnswer = () => {

        const style0 = data.answers[0].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;
        const style1 = data.answers[1].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;
        const style2 = data.answers[2].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;
        const style3 = data.answers[3].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;

        return (
            <>
                <div style={{ display: 'flex', height: 60, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={style0}>
                        <span style={styles.answerText}>{data.answers[0].text}</span>
                    </div>
                    <div style={style1}>
                        <span style={styles.answerText}>{data.answers[1].text}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', height: 60, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={style2}>
                        <span style={styles.answerText}>{data.answers[2].text}</span>
                    </div>
                    <div style={style3}>
                        <span style={styles.answerText}>{data.answers[3].text}</span>
                    </div>
                </div>
            </>
        )
    }

    const renderIncorrectAnswer = () => {

        const style0 = data.answers[0].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;
        const style1 = data.answers[1].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;
        const style2 = data.answers[2].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;
        const style3 = data.answers[3].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;

        return (
            <>
                <div style={{ display: 'flex', height: 60, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={style0}>
                        <span style={styles.answerText}>{data.answers[0].text}</span>
                    </div>
                    <div style={style1}>
                        <span style={styles.answerText}>{data.answers[1].text}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', height: 60, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={style2}>
                        <span style={styles.answerText}>{data.answers[2].text}</span>
                    </div>
                    <div style={style3}>
                        <span style={styles.answerText}>{data.answers[3].text}</span>
                    </div>
                </div>
            </>
        )
    }

    const getAudio = async (word) => {
        try {
            const audioUrl = urls.api_url + urls.get_audio.replace('{soundKey}', word);
            const audio = new Audio(audioUrl);

            audio.play().catch((error) => {
                //console.error('Audio playback failed:', error);
            });
        } catch (error) {
            //console.error('Error in getAudio:', error);
        }
    };


    return (
        <div style={{ width: '100wh', height:'100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>

            <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', width: '90%', height: '90%', border: `2px solid ${mycolors.generalColor}`, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                    {
                        answerStatus != 'show-default'
                            ? <div style={{ height: 50, width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} onClick={() => { getAudio(data.answers.find(x => x.isAnswer == true).text) }}>
                                <img src='../assets/images/grammar-images/word-sound-icon.png' style={{ cursor: 'pointer', height: 35, width: 35, marginRight: 5 }} />
                            </div>
                            : <div style={{ height: 50, width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            </div>
                    }

                    <div style={{ display: 'flex', flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        {data.meanings.map((item, index) => (
                            <span key={`${item}-${index}`} style={{
                                textAlign: 'center', color: mycolors.darkThemeGreen,
                                fontWeight: '700',
                                fontSize: 23,
                                marginVertical: 4,
                                textAlign: 'center'
                            }}>
                                {item}
                            </span>
                        ))}
                    </div>

                </div>
            </div>


            <div style={{ height: 140, width: '90%', alignItems: 'center', justifyContent: 'center', borderRadius: 10, overflow: 'hidden' }}>
                {
                    answerStatus == 'show-default'
                        ? renderAnswers()
                        : answerStatus == 'show-correct'
                            ? renderCorrectAnswer()
                            : answerStatus == 'show-incorrect'
                                ? renderIncorrectAnswer()
                                : <></>
                }
            </div>

            <div style={{ height: 60, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {
                    isButtonValid
                        ?
                        <div style={{ height: 60, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <div disabled={!isButtonValid} onClick={() => { handleNextButton() }} style={{ opacity: isButtonValid ? 1 : 0.5, height: 50, width: 100, backgroundColor: mycolors.generalColor, borderWidth: 1, borderColor: mycolors.generalColor, marginRight: 10, borderRadius: 10, alignItems: 'center', justifyContent: 'center', display:'flex', cursor:'pointer' }}>
                                <span style={{ color: mycolors.black, fontWeight: '600', fontSize: 16 }}>{currentIndex === listLength ? resources.Finish : resources.Next}</span>
                            </div>
                        </div>
                        : <></>
                }
            </div>

            {/* <div style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor:'blue' }}>
                <div style={{ flex: 1, display:'flex', width: '90%', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ height: '95%', width: '100%', alignItems: 'center', justifyContent: 'center', borderRadius: 10, overflow: 'hidden' }}>

                        {
                            answerStatus != 'show-default'
                                ? <div style={{ height: 40, width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ height: 40, width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <div onClick={() => { getAudio(data.answers.find(x => x.isAnswer == true).text) }} style={{ display: 'flex', flex: 1, width: '100%', alignItems: 'flex-end' }}>
                                            <img src='../assets/images/grammar-images/word-sound-icon.png' style={{ height: 35, width: 35, marginRight: 5 }} />
                                        </div>
                                    </div>
                                </div>
                                : <></>
                        }

                        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: answerStatus != 'show-default' ? 40 : 0 }}>
                            {data.meanings.map((item, index) => (
                                <span key={`${item}-${index}`} style={{
                                    textAlign: 'center', color: mycolors.darkThemeGreen,
                                    fontWeight: '700',
                                    fontSize: 23,
                                    marginVertical: 4,
                                    textAlign: 'center'
                                }}>
                                    {item}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ height: 140, width: '90%', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ height: 140, width: '100%', alignItems: 'center', justifyContent: 'center', borderRadius: 10, overflow: 'hidden' }}>
                        {
                            answerStatus == 'show-default'
                                ? renderAnswers()
                                : answerStatus == 'show-correct'
                                    ? renderCorrectAnswer()
                                    : answerStatus == 'show-incorrect'
                                        ? renderIncorrectAnswer()
                                        : <></>
                        }
                    </div>
                </div>
            </div>
            <div style={{ height: 60, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor:'orange' }}>
                {
                    isButtonValid
                        ?
                        <div style={{ height: 60, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <div disabled={!isButtonValid} onClick={() => { handleNextButton() }} style={{ opacity: isButtonValid ? 1 : 0.5, height: 50, width: 100, backgroundColor: mycolors.generalColor, borderWidth: 1, borderColor: mycolors.generalColor, marginRight: 10, borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: mycolors.black, fontWeight: '600', fontSize: 16 }}>{currentIndex === listLength ? resources.Finish : resources.Next}</span>
                            </div>
                        </div>
                        : <></>
                }
            </div> */}

        </div>



    );
}

export default QuizTest;


const styles = {




    answerContainer: {
        height: 50,
        width: '45%',
        backgroundColor: '#171620',
        borderRadius: 10,
        border: `2px solid #00A67E`,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },

    correctAnswerContainer: {
        height: 50,
        width: '45%',
        backgroundColor: '#00A67E',
        border: `2px solid #00A67E`,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },

    incorrectAnswerContainer: {
        height: 50,
        width: '45%',
        backgroundColor: '#171620',
        borderRadius: 10,
        border: `2px solid #FF4500`,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },

    answerText: {
        fontSize: 16,
        color: 'white',
        fontWeight: '500',
        textAlign: 'center'
    }
};