const mycolors = {

    navy1: '#1A1925',
    darkThemeGreen: '#2DEBC9',
    darkGrey: '#484848',

    textboxGrey: '#8C8C8C',


    sweetFashionBlue: '#17a4e3',
    sweetFashionPink: '#E53D8B',
    sweetFashionGreen: '#16c9b4',

    backGroundColor: '#1F1D2B',
    black: '#000',
    white: '#fff',
    grey: '#B2BEC3',
    generalColor: '#2DEBC9',
    generalPinkColor: '#8851A8',
    correctGreen: '#32de84',
    companyColor: '#F6DC00',
    lightBlue: '#D4DEFF',
    dirtyWhite: '#E3E3E3',
    invalidRed: '#FF6768',
    formBorderColor: '#5B6481',
    yellow: '#FED000'
}

export { mycolors }