import React, { useEffect, useState } from 'react';
import { mycolors } from '../helpers/mycolors';
import { resources } from '../helpers/resources';
import { urls } from '../utils/api';

const PuzzleDesign = ({ dataParam, handleNextButton, handlePreviousButton, currentIndex, listLength }) => {

    const [data, setData] = useState(dataParam);
    const [isButtonValid, setIsButtonValid] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [answerArray, setAnswerArray] = useState();
    const [mixAnswer, setMixAnswer] = useState();
    const [answer, setAnswer] = useState();
    const [tempAnswerArray, setTempAnswerArray] = useState();
    const [loading, setLoading] = useState(true);
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);

    useEffect(() => {
        let answerArrayParam = dataParam.answerArray;
        setAnswerArray([...answerArrayParam]);
        let mixAnswerParam = dataParam.mixAnswer;

        setMixAnswer([...mixAnswerParam]);

        let answerParam = dataParam.answer;
        setAnswer([...answerParam]);
        setTempAnswerArray([...answerParam]);

        setTimeout(() => {
            setLoading(false);
        }, 100);

    }, []);

    if (loading) {
        return <span style={{ color: mycolors.generalColor, fontSize: 50 }}>LOADIIIING</span>
    }

    const pushAnswerArray = (alphabet, i) => {
        let _mixAnswer = [...mixAnswer];
        let index = _mixAnswer.findIndex(x => x == ' ')
        _mixAnswer[index] = alphabet;

        let _answerArray = [...answerArray];
        _answerArray[i] = ' ';

        setAnswerArray(_answerArray);
        setMixAnswer(_mixAnswer);
    }


    const renderAnswerButtons = () => {

        return answerArray.map((item, index) => {
            const customStyle = {
                ...styles.buttonStyle,
                borderColor: item === ' ' ? mycolors.grey : mycolors.black
            };


            return (
                <div key={index} style={{ width: 75, height: 75, paddingLeft: 10, paddingBottom: 10 }}>
                    <div onClick={() => pushAnswerArray(item, index)} style={customStyle}>
                        <span style={{ color: mycolors.white, fontSize: 30 }}>{item}</span>
                    </div>
                </div>
            );
        });

    };


    // const renderAnswerButtons = () => {
    //     let buttons = [];

    //     for (let i = 0; i < answerArray.length; i++) {
    //         buttons.push(
    //             <div key={i} style={{ width: '18%', paddingLeft: 10, paddingBottom: 10 }}>
    //                 <div onClick={() => pushAnswerArray(answerArray[i], i)} style={[styles.buttonStyle, answerArray[i] == ' ' ? { borderColor: mycolors.grey } : { borderColor: mycolors.black }]}>
    //                     <span style={{ color: mycolors.black }}>{answerArray[i]}</span>
    //                 </div>
    //             </div>
    //         )
    //     }

    //     return (
    //         buttons
    //     )
    // }

    const checkButtons = () => {
        let buttons = [];
        //let correctStyle = { borderColor: mycolors.correctGreen, backgroundColor: mycolors.correctGreen };
        let correctStyle = { border: `2px solid ${mycolors.correctGreen}`, backgroundColor: mycolors.backGroundColor };
        let correctTextStyle = { color: mycolors.correctGreen, fontSize: 30 }

        //let incorrectStyle = { borderColor: mycolors.invalidRed, backgroundColor: mycolors.invalidRed };
        let incorrectStyle = { border: `2px solid ${mycolors.invalidRed}`, backgroundColor: mycolors.backGroundColor };
        let incorrectTextStyle = { color: mycolors.invalidRed, fontSize: 30 }

        let style = correctStyle;
        let textStyle = correctTextStyle;
        for (let k = 0; k < answerArray.length; k++) {
            if (tempAnswerArray[k] != answerArray[k]) {
                style = incorrectStyle;
                textStyle = incorrectTextStyle;
            }
        }

        for (let i = 0; i < answerArray.length; i++) {

            const customstyle = {
                ...styles.buttonStyle,
                ...style
            };

            const customTextStyle = {
                ...textStyle
            };

            buttons.push(
                <div key={i} style={{ width: 75, height: 75, paddingLeft: 10, paddingBottom: 10 }}>
                    {
                        <div style={customstyle}>
                            <span style={customTextStyle}>{answerArray[i]}</span>
                        </div>
                    }
                </div>
            )
        }

        return (
            buttons
        )
    }

    const renderButtons = () => {
        let buttons = [];

        for (let i = 0; i < mixAnswer.length; i++) {

            const customstyle = {
                ...styles.buttonStyle,
                borderColor: mixAnswer[i] === ' ' ? mycolors.backGroundColor : mycolors.black,
            };

            buttons.push(
                <div key={i} style={{ width: 75, height: 75, paddingLeft: 10, paddingBottom: 10 }}>
                    <div disabled={mixAnswer[i] == ' '} onClick={() => pushArray(mixAnswer[i], i)} style={customstyle}>
                        <span style={{ color: mycolors.white, fontSize: 30 }}>{mixAnswer[i]}</span>
                    </div>
                </div>

            )
        }

        return (
            buttons
        )
    }


    const renderCorrectAnswer = () => {

        if (isCorrectAnswer) {
            return (
                <div style={{ height: 80, width: '100%', justifyContent: 'center', alignItems: 'center', borderRadius: 16, backgroundColor: mycolors.navy1, border: `1px solid ${mycolors.correctGreen}` }}>
                    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', height: '100%', width: '80%', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: 14, fontWeight: '600', color: mycolors.correctGreen }}>{resources.YouAnsweredCorrectly}</span>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={{ height: 80, width: '100%', justifyContent: 'center', alignItems: 'center', borderRadius: 16, backgroundColor: mycolors.navy1, border: `1px solid ${mycolors.invalidRed}` }}>
                    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', height: '100%', width: '80%', justifyContent: 'center', alignItems: 'center', flexDirection:'column' }}>
                            <span style={{ fontSize: 14, fontWeight: '600', color: mycolors.invalidRed }}>{resources.TheCorrectAnswerIsAsFollows}</span>
                            <span style={{ fontSize: 20, fontWeight: '600', color: mycolors.darkThemeGreen }}>{tempAnswerArray.join('')}</span>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const pushArray = (alphabet, i) => {
        let _answerArray = [...answerArray];
        let index = _answerArray.findIndex(x => x == ' ')
        _answerArray[index] = alphabet;

        let _mixAnswer = [...mixAnswer];
        _mixAnswer[i] = ' ';


        setAnswerArray(_answerArray);
        setMixAnswer(_mixAnswer);

        if (_answerArray[_mixAnswer.length - 1] != ' ') {
            setShowResult(true);

            let count = 0;
            for (let i = 0; i < _answerArray.length; i++) {
                if (tempAnswerArray[i] != _answerArray[i]) {
                    count = count + 1;
                }
            }


            setIsCorrectAnswer(count == 0);

            let resultModel = {
                result: count == 0,
                input: _answerArray.join("")
            }

            setIsButtonValid(true);
            //puzzleDesignResult(resultModel)
        }
    }

    const getAudio = async (word) => {
        try {
            const audioUrl = urls.api_url + urls.get_audio.replace('{soundKey}', word);
            const audio = new Audio(audioUrl);

            audio.play().catch((error) => {
                //console.error('Audio playback failed:', error);
            });
        } catch (error) {
            //console.error('Error in getAudio:', error);
        }
    };

    return (
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
            <div style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>

                <div style={{ flex: 1, display: 'flex', width: '90%', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '95%', width: '100%', alignItems: 'center', justifyContent: 'center', borderRadius: 10, border: `2px solid ${mycolors.generalColor}`, overflow: 'hidden' }}>

                        {
                            data.languageEnumId == 1
                                ? <div style={{ height: 40, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ height: 40, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <div onClick={() => { getAudio(data.word) }} style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'flex-end' }}>
                                            <img src='../assets/images/grammar-images/word-sound-icon.png' style={{ height: 35, width: 35, marginRight: 5 }} />
                                        </div>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            data.languageEnumId != 1 && showResult
                                ? <div style={{ height: 40, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ height: 40, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <div onClick={() => { getAudio(data.word) }} style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'flex-end' }}>
                                            <img src='../assets/images/grammar-images/word-sound-icon.png' style={{ height: 35, width: 35, marginRight: 5 }} />
                                        </div>
                                    </div>
                                </div>
                                : <></>

                        }

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: (data.languageEnumId == 1 || (data.languageEnumId != 1 && showResult)) ? 40 : 0 }}>
                            {
                                data.languageEnumId == 0
                                    ? data.cardTexts.map((text, index) => (
                                        <div key={index} style={{ alignItems: 'center' }}>
                                            <span style={{ fontSize: 23, textAlign: 'center', color: mycolors.darkThemeGreen, fontWeight: '700' }}>
                                                {text}
                                            </span>
                                        </div>
                                    ))
                                    : <span style={{ fontSize: 23, textAlign: 'center', color: mycolors.darkThemeGreen, fontWeight: '700', paddingHorizontal: 5 }}>
                                        {data.cardTexts.join(', ')}
                                    </span>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', width: '90%', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={styles.buttonView}>
                        {!showResult
                            ? renderAnswerButtons()
                            : checkButtons()}
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', width: '90%', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={styles.buttonView}>
                        {!showResult
                            ? renderButtons()
                            : renderCorrectAnswer()}
                    </div>
                </div>

            </div>
            <div style={{ height: 60, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {
                    isButtonValid
                        ?
                        <div style={{ height: 60, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>

                            {/* {
                                currentIndex === 0
                                    ? <div style={{ height: 50, width: 100 }}></div>
                                    : <div onClick={() => { handlePreviousButton() }} style={{ height: 50, width: 100, backgroundColor: mycolors.generalColor, borderWidth: 1, borderColor: mycolors.generalColor, marginRight: 10, borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
                                        <span style={{ color: mycolors.black, fontWeight: '600', fontSize: 16 }}>{resources.Previous}</span>
                                    </div>
                            } */}

                            <div disabled={!isButtonValid} onClick={() => { handleNextButton() }} style={{ cursor:'pointer', display:'flex', opacity: isButtonValid ? 1 : 0.5, height: 50, width: 100, backgroundColor: mycolors.generalColor, borderWidth: 1, borderColor: mycolors.generalColor, marginRight: 10, borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: mycolors.black, fontWeight: '600', fontSize: 16 }}>{currentIndex === listLength ? resources.Finish : resources.Next}</span>
                            </div>
                        </div>
                        : <></>
                }
            </div>

        </div>

    );
}

export default PuzzleDesign;

const styles = {
    buttonView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginRight: 10,
        marginTop: 20,
        paddingBottom: 10,
        flexWrap: 'wrap',
        height: '80%',
        width: '100%'
    },
    buttonStyle: {
        cursor: 'pointer',
        borderRadius: 5,
        height: 40,
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: "center",
        backgroundColor: mycolors.backGroundColor,
        border: `2px solid ${mycolors.darkGrey}`
    }
};
