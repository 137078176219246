
import React, { useState, useEffect } from 'react';
import { postMethod, urls } from '../utils/api';
import { mycolors } from '../helpers/mycolors';
import { useLocation, useNavigate } from 'react-router-dom';

const WordRepeatDoneScreen = ({ route }) => {
    const navigate = useNavigate();
    const location = useLocation();

 
    return (
        <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: mycolors.backGroundColor }}>
            <div onClick={() => { navigate('/seviyelerine-gore-ingilizce-kelimeler')}} style={{ cursor: 'pointer', marginTop: 15, width: 250, borderRadius: 10, height: 50, border: `1px solid ${mycolors.generalColor}`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><span style={{ fontSize: 14, fontWeight: '500', color: mycolors.generalColor }}>Ezber sayfasına geri dön</span></div>
        </div>
    )
}


export default WordRepeatDoneScreen;