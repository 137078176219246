import React from "react";
import Homepage from './screens/Homepage';
import WordScreen from './screens/WordScreen';
import WordMemorizeScreen from './screens/WordMemorizeScreen';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TopMenuLayout from "./components/TopMenuLayout";
import RepeatScreen from "./screens/RepeatScreen ";
import MatchingScreen from "./screens/MatchingScreen";
import ExercisesScreen from "./screens/ExercisesScreen";
import WordRepeatDoneScreen from "./screens/WordRepeatDoneScreen";
import MobileCopyRight from "./screens/MobileCopyRight";
import TemporaryScreen from "./screens/TemporaryScreen";
import TemporaryScreen2 from "./screens/TemporaryScreen2";
import TemporaryScreen3 from "./screens/TemporaryScreen3";

const App = () => {
  return (
    <Router>


      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/homepage" element={<Homepage />} />
        <Route path="/seviyelerine-gore-ingilizce-kelimeler" element={<WordScreen />} />
        <Route path="/ingilizce-kelime-ezberle" element={<WordMemorizeScreen />} />
        <Route path="/tekrar-et" element={<RepeatScreen />} />
        <Route path="/eslestirme-oyunu" element={<MatchingScreen />} />
        <Route path="/egzersizler" element={<ExercisesScreen />} />
        <Route path="/mobile-copyright" element={<MobileCopyRight />} />
        <Route path="/tekrar-sonuc" element={<WordRepeatDoneScreen />} />
        <Route path="/temporary" element={<TemporaryScreen />} />
        <Route path="/temporary2" element={<TemporaryScreen2 />} />
        <Route path="/temporary3" element={<TemporaryScreen3 />} />
        
      </Routes>
    </Router>
  );
}

export default App;
