import React from 'react';
import './Loading.css';
import { mycolors } from '../helpers/mycolors';

const Loading = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', width: '100vw', height: '100vh', backgroundColor: mycolors.backGroundColor }}>
            <div className="loader"></div>
            <span style={{ marginTop: 10, fontSize: 15, fontWeight: '600', color: mycolors.generalColor }}>Yukleniyor</span>
        </div>
    );
};

export default Loading;
