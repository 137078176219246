import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { postMethod, urls } from '../utils/api';
import { mycolors } from '../helpers/mycolors';
import { useNavigate } from 'react-router-dom';
import { resources } from '../helpers/resources';
import QuizTest from '../components/QuizTest';
import QuizMulti from '../components/QuizMulti';
import PuzzleDesign from '../components/PuzzleDesign';
import Loading from '../components/Loading';

const RepeatScreen = () => {
    const location = useLocation();
    const usage = location.state?.usage || 1;
    const navigate = useNavigate();

    const [showMeanings, setShowMeanings] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [words, setWords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [doneWordIds, setDoneWordIds] = useState([]);

    useEffect(() => {
        getDoneWordIds();
    }, []);

    const getDoneWordIds = async () => {
        try {

            //await AsyncStorage.setItem('doneWordIds', '[]');


            const _doneWordIds = localStorage.getItem('doneWordIds');
            if (_doneWordIds == null || _doneWordIds == undefined) {
                localStorage.setItem('doneWordIds', '[]');
                setDoneWordIds([]);
                getWords([]);
            }
            else {
                const storedList = JSON.parse(_doneWordIds);

                const eliminatedWordIds = storedList.filter(x => x.type != 'ignore' && x.usage == usage);
                const currentDate = new Date().toISOString().split('T')[0];
                const _today = new Date().toISOString().split('T')[0];

                const allTimes = eliminatedWordIds;
                setDoneWordIds(eliminatedWordIds);
                getWords(eliminatedWordIds);

            }

        } catch (error) {

        }
    };

    const getWords = async (_doneWordIds) => {
        try {
            const ids = _doneWordIds.map(item => item.id);
            const response = await postMethod(urls.get_repeat_word_model_byids, { ids: ids });
            setWords(response);
            setLoading(false);
        } catch (error) {
            //console.log(error)
            setLoading(false);
        }
    };


    const handleNextButton = () => {
        if (currentIndex === words.length - 1) {
            navigate('/tekrar-sonuc');
        }
        else {
            setCurrentIndex(currentIndex + 1);
        }
    }

    const handlePreviousButton = () => {
        if (currentIndex < 1) {
            setCurrentIndex(0);
        }
        else {
            setCurrentIndex(currentIndex - 1);
        }
    }

    const renderDesign = (item, index) => {

        const sampleMultiLineData = {
            meanings: [
                'Meaning 1',
                'Meaning 2',
                'Meaning 3'
            ],
            answers: [
                { text: 'Answer 1', isAnswer: false },
                { text: 'Answer 2', isAnswer: false },
                { text: 'Answer 3', isAnswer: false },
                { text: 'Answer 4', isAnswer: true },
                { text: 'Answer 5', isAnswer: false },
                { text: 'Answer 6', isAnswer: false }
            ]
        }

        const samplelongmultiselectData = {
            word: 'Sample Word',
            correctAnswerCount: 2,
            userAnswers: [],
            answers: [
                { id: 1, text: 'Answer 1', isAnswer: true, selected: false, index: 0 },
                { id: 2, text: 'Answer 2', isAnswer: false, selected: false, index: 1 },
                { id: 3, text: 'Answer 3', isAnswer: true, selected: false, index: 2 },
                { id: 4, text: 'Answer 4', isAnswer: false, selected: false, index: 3 },
                { id: 5, text: 'Answer 5', isAnswer: false, selected: false, index: 4 },
                { id: 6, text: 'Answer 6', isAnswer: true, selected: false, index: 5 }
            ]
        };

        const samplePuzzleDesignData = {
            languageEnumId: 0, // Assuming 0 for English, 1 for another language
            cardTexts: ['Selamlamak', 'Selam'], // Array of text for the card
            pronounce: '', // Pronunciation text
            addedFavourite: false, // Whether the item is added to favorites
            answerArray: [' ', ' ', ' ', ' ', ' '], // Array of correct answer characters
            mixAnswer: ['E', 'L', 'L', 'H', 'O'], // Array for mixed answer characters
            answer: ['H', 'E', 'L', 'L', 'O'], // Array of correct answer characters (for comparison)
            wordId: 'your_word_id_here', // Unique identifier for the word
        }

        if (item.type == 'multiselect') {
            return <QuizTest data={item} key={item.wordId}
                handleNextButton={handleNextButton}
                handlePreviousButton={handlePreviousButton}
                currentIndex={currentIndex} listLength={words.length} />
        }

        if (item.type == 'longmultiselect') {
            return <QuizMulti key={item.wordId} dataParam={item} handleNextButton={handleNextButton}
                handlePreviousButton={handlePreviousButton}
                currentIndex={currentIndex} listLength={words.length} />
        }


        if (item.type == 'puzzledesign') {
            return <PuzzleDesign key={item.wordId}
                handleNextButton={handleNextButton}
                handlePreviousButton={handlePreviousButton}
                currentIndex={currentIndex} listLength={words.length}
                dataParam={item} />
        }

        return <></>;
    }


    if (loading) {
        return <Loading />
    }

    return (
        <div style={{ width: '100%', height: '100vh', backgroundColor: mycolors.backGroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

            <div style={{ marginTop: 20, height: 35, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>
                <div style={{ height: 25, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ height: 25, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <progress width={'90%'} value={currentIndex != 0 ? (currentIndex / (words.length - 1)) : 0} /> */}
                        <span style={{ fontSize: 20, fontWeight: '600', color: mycolors.white }}>{currentIndex + 1} / {words.length}</span>

                    </div>
                    <div style={{ height: 25, width: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ cursor: 'pointer', width: '100%', height: '100%', borderWidth: 2, borderColor: mycolors.generalColor, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => { navigate('/seviyelerine-gore-ingilizce-kelimeler') }}>
                            <span style={{ color: mycolors.darkThemeGreen, fontWeight: '700', fontSize: 16 }}>X</span>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                {
                    words.map((item, index) => (
                        currentIndex == index &&
                        renderDesign(item, index)
                    ))
                }
            </div>
            <div style={{ height: 20, width: '100%' }}></div>

        </div>
    );
}

export default RepeatScreen;
