import { useEffect, useState } from "react";
import { mycolors } from "../helpers/mycolors";
import { useNavigate } from 'react-router-dom';
import TopMenuLayout from "../components/TopMenuLayout";

const TemporaryScreen = () => {

    const sentences = [
        'It has been a while since we last spoke',
        'Your suggestions were among my favorites',
        'They played a crucial role in shaping my career',
        'As part of the hiring process, the company requires an acadamic reference, and I immediately thought of you',
        'Given: goz onunde bulunduruldugunda, dikkata alindiginda',
        'Given the weather forecast, we decided to postpone the picnic',
        'Given the difficulty of the exam, I think I did well',
        'I believe your recommendation would add significant value to my application',
        'It directly aligns with this role',
        'I have always enjoyed dining at your restaurant. However, my most recent visit on Friday was deeply disappointing',
        'Despite this, we were served ... (buna ragmen)',
        'We politely requested a freshly cooked meal, the staff declined, stating they were too busy',
        'Shockingly, they did not even offer an apology',
        'To make matters worse, there was no manager available to address our concern',
        'Surprisingly, I have also observed that ...',
        'E-books offer significant advantages over traditional paper books',
        'We are group of approximately ten people, all between the ages of 18 and 20',
        'To ensure that your hotel meets our expectations',
        'Although we are currently considering a two-day visit, we may also explore seasonal membership options if we find the experience enjoyable',
        'It provides numerous benefits to animals, children, and the local economy',
        'The zoo plays a vital role in rescuing',
        'I hope this email finds you well',
        'It must have been sometime between 10:00 a.m. and 11:00 a.m.',
        'Please let me know if any further details are needed',
        'I kindly request you to coordinate with the Secretary to review the footage',
        'Please feel free to reach out if you need any additional details',
        'I just wanted to send you a quick email to talk about our family gathering this summer',
        'There was, however, one thing I would like to bring to your attention',
        'Thank you for taking this into consideration, and I look forward to another great Festival next year',
        'I am writing to express a concern I have about the construction project near my home',
        'There is, however, one problem I have with the new layout',
        '.... on Friday, May 10, 2025, at 5:45 p.m., at Harvest Restaurant',
        'I would be happy to provide you with any other information you might need',
        'I am so sorry to say that a situation has came up, and I will not ...',
        'A tennis court can only be used by two people at a time. I think having a swimming pool would attract more families to the gym',
        '... provide(s) for greater advantages to a larger portion of the community',
        '... detailed information regarding the available activities',
        'Could you kindly provide details about ...',
        'We have limited resources, so we must plan accordingly',
        'I appreciate their prompt response and professionalism',
        'I was informed that the repair would take ...',
        'I followed up with representative',
        'I kindly request an urgent update on the repair status',
        'I appreciate your immediate attention to this issue and look forward to your response',
        'Safety risks',
        'Practical benefits',
        'As a jagger myself, (bende bir kosucu olarak)',
        'for all the parties involved (ilgili tum taraflar icin)',
        'Essential goods (temel ihtiyac urunleri)',
        'Part of modern life',
        'a baseball stadium would positively impact our economy',
        'To avoid such misunderstandings in the future, I strongly recommend updating the product images on your website',
        'but lately the noise levels have been too high for me to concentrate',
        'lack of notification about route changes',
        'Unfortunately, there was no sign at the bus stop, nor was there an update on your official website',
        'Kindly review these documents and update my records accordingly',
        'The staff members are always polite and welcoming',
        '... need(s) significant improvement',
        "The cafeteria's heating system is not functioning properly, making it extremely uncomfortable to eat during winter",
        'Many students prefer to leave quickly rather than enjoy their meals due to the cold environment',
        'As part of my daily routine, I went for a morning run, but upon returning, I realized that my watch was missing',
        'bazi durumlarda help => assist kullan daha iyi (that could assist in locating it)',
        'Please review them and advise me on the next steps.',
        'during the mailing process or lost in transit',
        'Although the letter states that activation requires a security code, which ensures unauthorized individuals cannot use it, I am still anxious about the possibility of misuse.',
        'Additionally, since I urgently require the card for my wedding expenses ...',
        'will make the situation worse.',
        'Many believe that their complaints will not lead to any meaningful action',
        'My card, ending in 5047, was used to purchase a DeLonghi EC155 Espresso Machine from Amazon.com on May 10, 2025, at 2:04 p.m.',
        'Kindly investigate this matter and correct my statement at your earliest convenience.',
        'I appreciate your prompt attention to this issue and look forward to your response.',
        'On the other hand, in my country, license suspension alone has not been effective',
        'In conclusion, heavier penalties would serve as a stronger deterrent against drunk driving, making roads safer for everyone.'
    ];

    const randomizedSentences = sentences.sort(() => Math.random() - 0.5);

    return (
        <div style={{ width: '100%', backgroundColor: mycolors.backGroundColor, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {randomizedSentences.map((sentence, index) => (
                <p style={{ color: mycolors.generalColor, fontSize: 14, fontWeight: '500' }} key={index}>{sentence}</p>
            ))}
        </div>
    )

}

export default TemporaryScreen;