
import axios from 'axios';

const urls = {
    api_url: 'https://ibra.blbfit.it',
    //api_url: 'http://localhost:5167',
    get_grammar_list_url: '/api/grammar/get-grammar-list',
    get_grammar_detail_url: '/api/grammar/get-grammar-detail',
    get_words: '/api/word/get-words',
    get_audio: '/api/audio/get-audio/{soundKey}',
    get_repeat_word_model: '/api/word/get-repeat-word-model',
    get_repeat_word_model_byids: '/api/word/get-repeat-word-model-byids',
    get_words_count: '/api/word/get-words-count',
    save_firebase_token: '/api/notification/save-firebase-token'
};

export { urls }

export async function postMethod(url, fromData) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': 'TR'
        },
    };

    const response = await axios.post(`${urls.api_url}${url}`, fromData, config);
    if (response.status != 200) {
        throw new Response('Failed to login process.', { status: 500 });
    }
    return response.data;
}

export async function postMethodWithHeader(url, data) {
    const token = localStorage.getItem('token')

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': 'TR'
        },
    };

    const response = await axios.post(`${urls.api_url}${url}`, data, config);
    if (response.status != 200) {
        throw new Response('Failed to login process.', { status: 500 });
    }
    return response.data;
}

export async function getMethod(url) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': 'TR'
        },
    };

    const response = await axios.get(`${urls.api_url}${url}`);
    if (response.status != 200) {
        throw new Response('Failed to login process.', { status: 500 });
    }
    return response.data;
}

export async function getMethodWithHeader(url) {
    const token = localStorage.getItem('token');

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': 'TR'
        },
    };

    const response = await axios.get(`${urls.api_url}${url}`, config);
    if (response.status != 200) {
        throw new Response('Failed to login process.', { status: 500 });
    }
    return response.data;
}