const resources = {
    CompanyName: 'Mr. Parrot',

    Dashboard: 'Ana sayfa',
    Settings: 'Ayarlar',
    Puzzle: 'Bulmaca',
    Words: 'Kelimeler',
    Repeat: 'Tekrar Et',
    Greetings: 'Selamlama',
    IntroduceYourSelf: 'Kendini Tanitma',
    SayingHowAreYou: 'Nasilsin Diye Sorma',
    SocialEtiquette: 'Sosyal Gorgu Kurallari',
    MeetingPeople: 'Birilerini tanima',
    Family: 'Aile',
    FamilyRelatives: 'Aile (Akrabalar)',
    DescribingPeople: 'Insanlari Tarif Etmek',
    Colors: 'Renkler',
    Start: 'Basla',
    StartAgain: 'Yeniden Basla',
    Previous: 'Onceki',
    Next: 'Sonraki',
    Finish: 'Bitir',
    Check: 'Kontrol Et',
    Perfect: 'Mukemmel',
    YouHaveDone: 'Bitirdin',
    YouHaveDoneAllWords: 'Tum Kelimeleri Bitirdin',
    Done: 'Bitir',
    TakeExam: 'Sinavi Ol',
    Note: 'Not',
    Level: 'Seviye',
    BeginnerWords: 'Baslangic Seviye Kelimeler',
    IntermediateWords: 'Orta Seviye Kelimeler',
    AdvanceWords: 'Ileri Seviye Kelimeler',
    KeepLearning: 'Ogrenmeye Devam',
    StartToLearning: 'Ogrenmeye Basla',
    IAlreadyKnow: 'Zaten Biliyorum',
    Memorize: 'Ezberle',
    ToSeeMeaningsPleaseClick: 'Anlamlarini gormek icin lutfen tiklayiniz',
    GotIt: 'Anladim',
    SampleWordPlay: 'Play',
    SampleWordPlayMeaning1: 'Oyun (isim)',
    SampleWordPlayMeaning2: 'Oynamak (fiil)',
    SampleWordPlayMeaning3: 'Calmak (fiil)',
    Today: 'Bugun',
    Last7Days: 'Son 7 Gun',
    Last30Days: 'Son 30 Gun',
    AllTimes: 'Tum zamanlar',
    WordRepeatSuccessDoneMessage: 'Harikasin. Ezberledigin kelimeleri surekli tekrar ederek kalici olarak ogrenmeni saglayabilirsin',
    SkipForNow: 'Simdilik Gec',
    BeginnerLevel: 'Baslangic Seviye',
    IntermediateLevel: 'Orta Seviye',
    AdvanceLevel: 'Ileri Seviye',
    Loading: 'Yukleniyor',
    WrongAnswer: 'Yanlis Cevap',
    TheCorrectAnswerIsAsFollows: 'Dogru cevap su sekilde',
    YouAnsweredCorrectly: 'Dogru cevapladin',
    PleaseTypeYourAnswer: 'Lutfen cevabinizi yaziniz',
    Exercises: 'Pratik',
    MatchGame:'Kelime eslestirme',
    AmongLearnt: 'Ogrendiklerim Arasindan',
    Random:'Rastgele',
    NewGame: 'Yeni oyun',
    
    
    
    
    QuizMultiInfoMessage: 'Bu soru {count} dogru cevap iceriyor!'
}

export { resources }