
import React, { useState, useEffect } from 'react';
import { postMethod, urls } from '../utils/api';
import { mycolors } from '../helpers/mycolors';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';

const MatchingScreen = ({ route }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const usage = location.state?.usage || 1;
    const type = location.state?.type || 'random';
    const [words, setWords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [doneWordIds, setDoneWordIds] = useState([]);
    const [myarray1, setMyarray1] = useState([]);
    const [myarray2, setMyarray2] = useState([]);
    const [firstChosen, setFirstChosen] = useState(null);
    const [secondChosen, setSecondChosen] = useState(null);
    const [doneBoxIds, setDoneBoxIds] = useState([]);
    const [hideMyarray1, setHideMyarray1] = useState([]);
    const [hideMyarray2, setHideMyarray2] = useState([]);
    const [selectedArray1, setSelectedArray1] = useState(null);
    const [selectedArray2, setSelectedArray2] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);


    const generateRandom1Array = () => {
        const numbers = Array.from({ length: 6 }, (_, i) => i);

        // Sayıları rastgele sıraya sokma (Fisher-Yates Shuffle)
        for (let i = numbers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
        }

        // Rastgele sıraya sokulmuş array'i state'e ata
        setMyarray1(numbers);
    };

    const generateRandom2Array = () => {
        const numbers = Array.from({ length: 6 }, (_, i) => i);

        // Sayıları rastgele sıraya sokma (Fisher-Yates Shuffle)
        for (let i = numbers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
        }

        // Rastgele sıraya sokulmuş array'i state'e ata
        setMyarray2(numbers);
    };

    useEffect(() => {
        generateRandom1Array();
        generateRandom2Array();
        getDoneWordIds();
    }, []);

    useEffect(() => {
        if (firstChosen != null && secondChosen != null) {
            setTimeout(() => {
                if (firstChosen === secondChosen) {
                    setHideMyarray1(prev => [...prev, selectedArray1]);
                    setHideMyarray2(prev => [...prev, selectedArray2]);

                    // Reset states
                    setSelectedArray1(null);
                    setSelectedArray2(null);
                    setFirstChosen(null);
                    setSecondChosen(null);
                    setDoneBoxIds(prevDoneBoxIds => [...prevDoneBoxIds, firstChosen]);
                } else {
                    setFirstChosen(null);
                    setSecondChosen(null);
                    setSelectedArray1(null);
                    setSelectedArray2(null);
                }
            }, 100);
        }
    }, [firstChosen, secondChosen]); // Only runs when dependencies change


    const getDoneWordIds = async () => {
        try {

            //await AsyncStorage.setItem('doneWordIds', '[]');


            const _doneWordIds = localStorage.getItem('doneWordIds');
            if (_doneWordIds == null || _doneWordIds == undefined) {
                localStorage.setItem('doneWordIds', '[]');
                setDoneWordIds([]);
                getWords([]);
            }
            else {
                const storedList = JSON.parse(_doneWordIds);
                setDoneWordIds(storedList);
                getWords(storedList);
            }

        } catch (error) {

        }
    };

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const getWords = async (_doneWordIds) => {
        try {

            const response = await postMethod(urls.get_words, { usage: usage });
            if (type == 'memorize') {
                const filterArray = _doneWordIds.filter(w => w.type == 'memorize');
                const newWords = response.filter(word => filterArray.some(doneWord => doneWord.id === word.id));
                const shuffledWords = shuffleArray(newWords);
                setWords(shuffledWords);
                setLoading(false);
            }

            if (type == 'random') {
                const newWords = response.filter(word => !_doneWordIds.some(doneWord => doneWord.id === word.id));
                const shuffledWords = shuffleArray(newWords);
                setWords(shuffledWords);
                setLoading(false);
            }

        } catch (error) {

            setLoading(false);
            setWords([]);
        }
    };

    if (loading) {
        return <Loading />
    }

    if (showSuccess) {
        return (
            <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: mycolors.backGroundColor }}>
                <span style={{ color: mycolors.generalColor, fontSize: 40, fontWeight: '600' }}>Tebrikler</span>

                <div onClick={() => {
                    window.location.reload()
                }} style={{ cursor: 'pointer', marginTop: 15, backgroundColor: mycolors.generalColor, width: 200, borderRadius: 10, height: 50, border: `1px solid ${mycolors.generalColor}`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><span style={{ fontSize: 14, fontWeight: '500', color: mycolors.black }}>Yeni oyun</span></div>

                <div onClick={() => {
                    navigate('/egzersizler')
                }} style={{ cursor: 'pointer', marginTop: 15, width: 200, borderRadius: 10, height: 50, border: `1px solid ${mycolors.generalColor}`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><span style={{ fontSize: 14, fontWeight: '500', color: mycolors.generalColor }}>Egzersiz sayfasina geri dön</span></div>
            </div>
        )
    }

    if (!loading && words.length < 12) {
        //navigation.navigate('MainTabPages', { screen: 'ExercisesScreen' })
        navigate('MatchingScreen');
    }

    if (!loading && doneBoxIds.length == 6) {
        //navigation.navigate('SuccessMatchingScreen', { type: type, usage: usage })
        setShowSuccess(true)
    }


    // if (firstChosen != null && secondChosen != null) {
    //     setTimeout(() => {
    //         if (firstChosen == secondChosen) {
    //             setHideMyarray1(prev => [...prev, selectedArray1]);
    //             setHideMyarray2(prev => [...prev, selectedArray2]);

    //             setSelectedArray1(null)
    //             setSelectedArray2(null)
    //             setFirstChosen(null)
    //             setSecondChosen(null)
    //             setDoneBoxIds(prevDoneBoxIds => [...prevDoneBoxIds, firstChosen]);
    //         }
    //         else {
    //             setFirstChosen(null)
    //             setSecondChosen(null)
    //             setSelectedArray1(null)
    //             setSelectedArray2(null)
    //         }
    //     }, 100);


    // }

    const renderBox = (firstItem, secondItem, index1, index2) => {

        return (
            <div style={{ height: '15%', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                {doneBoxIds.includes(firstItem.id)
                    ? <div style={{ height: '100%', width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    </div>
                    : <div style={{ height: '100%', width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div onClick={() => { setFirstChosen(firstItem.id); setSelectedArray1(index1); }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90%', height: '90%', backgroundColor: selectedArray1 == index1 ? mycolors.generalColor : mycolors.backGroundColor, borderRadius: 5, border: `1px solid ${mycolors.generalColor}` }}>
                            <span style={{ textAlign: 'center', color: selectedArray1 == index1 ? mycolors.backGroundColor : mycolors.white, fontWeight: '500', fontSize: 14 }}>{firstItem.word}</span>
                        </div>
                    </div>
                }

                {doneBoxIds.includes(secondItem.id)
                    ? <div style={{ height: '100%', width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    </div>
                    : <div style={{ height: '100%', width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div onClick={() => { setSecondChosen(secondItem.id); setSelectedArray2(index2); }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90%', height: '90%', backgroundColor: selectedArray2 == index2 ? mycolors.generalColor : mycolors.backGroundColor, borderRadius: 5, border: `1px solid ${mycolors.generalColor}` }}>
                            <span style={{ textAlign: 'center', color: selectedArray2 == index2 ? mycolors.backGroundColor : mycolors.white, fontWeight: '500', fontSize: 14 }}>{secondItem.meanings.map(item => item.mean).join(", ")}</span>
                        </div>
                    </div>
                }

            </div>
        )
    }

    const render1 = () => {
        return renderBox(words[myarray1[0]], words[myarray2[0]], myarray1[0], myarray2[0]);
    };

    const render2 = () => {
        if (words[2] != null && words[3] != null) {
            return renderBox(words[myarray1[1]], words[myarray2[1]], myarray1[1], myarray2[1]);
        }
        else {
            return null;
        }
    };

    const render3 = () => {
        if (words[4] != null && words[5] != null) {
            return renderBox(words[myarray1[2]], words[myarray2[2]], myarray1[2], myarray2[2]);
        }
        else {
            return null;
        }
    };

    const render4 = () => {
        if (words[6] != null && words[7] != null) {
            return renderBox(words[myarray1[3]], words[myarray2[3]], myarray1[3], myarray2[3]);
        }
        else {
            return null;
        }
    };

    const render5 = () => {
        if (words[8] != null && words[9] != null) {
            return renderBox(words[myarray1[4]], words[myarray2[4]], myarray1[4], myarray2[4]);
        }
        else {
            return null;
        }
    };

    const render6 = () => {
        if (words[10] != null && words[11] != null) {
            return renderBox(words[myarray1[5]], words[myarray2[5]], myarray1[5], myarray2[5]);
        }
        else {
            return null;
        }
    };

    return (
        <div style={{ width: '100%', height: '100vh', backgroundColor: mycolors.backGroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {render1()}
            {render2()}
            {render3()}
            {render4()}
            {render5()}
            {render6()}
        </div >
    );
}


export default MatchingScreen;