import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mycolors } from '../helpers/mycolors';

const TopMenuLayout = ({ _currentpage }) => {

    const [currentpage, setCurrentpage] = useState(_currentpage);

    const navigate = useNavigate();

    const activeStyle = {
        margin: '0px 5px',
        fontSize: 12,
        backgroundColor: mycolors.generalColor,
        padding: '10px 15px',
        borderRadius: 25,
        color: mycolors.black,
        cursor: 'pointer',
        position: 'relative'
    };

    const inactiveStyle = {
        margin: '0px 5px',
        fontSize: 12,
        backgroundColor: mycolors.backGroundColor,
        padding: '10px 15px',
        borderRadius: 25,
        color: mycolors.generalColor,
        cursor: 'pointer'
    };

    const handlePageClick = (page, route, status) => {
        setCurrentpage(page);
        navigate(route);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', width: '100%', height: 70, backgroundColor: mycolors.backGroundColor }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div onClick={() => { setCurrentpage('homepage'); handlePageClick('homepage', '/homepage', false) }}
                    style={currentpage == ('homepage' || ' ') ? activeStyle : inactiveStyle}>
                    <span>Anasayfa</span>
                </div>
                <div onClick={() => { setCurrentpage('seviyelerine-gore-ingilizce-kelimeler'); handlePageClick('seviyelerine-gore-ingilizce-kelimeler', '/seviyelerine-gore-ingilizce-kelimeler', false) }}
                    style={currentpage == 'seviyelerine-gore-ingilizce-kelimeler' ? activeStyle : inactiveStyle}>
                    <span>Öğrenmeye başla</span>
                </div>
                <div onClick={() => { setCurrentpage('egzersizler');  handlePageClick('egzersizler', '/egzersizler', false) }}
                    style={currentpage == 'egzersizler' ? activeStyle : inactiveStyle}>
                    <span>Egzersizler</span>
                </div>
            </div>
            <div style={{ width: '95%', height: 2, backgroundColor: mycolors.generalColor }}>

            </div>
        </div>
    );
};

export default TopMenuLayout;