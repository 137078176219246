
import React, { useState, useEffect } from 'react';
import { mycolors } from '../helpers/mycolors';
import { resources } from '../helpers/resources';
import { useLocation, useNavigate } from 'react-router-dom';
import TopMenuLayout from '../components/TopMenuLayout';

const ExercisesScreen = () => {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('');
    const navigate = useNavigate();

    const [hideBeginner, setHideBeginner] = useState(false);
    const [hideIntermediate, setHideIntermediate] = useState(false);
    const [hideAdvance, setHideAdvance] = useState(false);

    useEffect(() => {
        getDoneWordIds();
    }, []);

    const getDoneWordIds = async () => {
        try {

            //await AsyncStorage.setItem('doneWordIds', '[]');

            const _doneWordIds = localStorage.getItem('doneWordIds');

            if (_doneWordIds == null || _doneWordIds == undefined) {
                localStorage.setItem('doneWordIds', '[]');
                setHideBeginner(true);
                setHideIntermediate(true);
                setHideAdvance(true);
            }
            else {
                const storedList = JSON.parse(_doneWordIds);

                const eliminateIgnoreWords = storedList.filter(x => x.type != 'ignore');
                const eliminateIgnoreWordsBeginner = storedList.filter(x => x.type != 'ignore' && x.usage == 1);
                const eliminateIgnoreWordsIntermediate = storedList.filter(x => x.type != 'ignore' && x.usage == 2);
                const eliminateIgnoreWordsAdvance = storedList.filter(x => x.type != 'ignore' && x.usage == 3);

                setHideBeginner((eliminateIgnoreWordsBeginner.length < 12));
                setHideIntermediate((eliminateIgnoreWordsIntermediate.length < 12));
                setHideAdvance((eliminateIgnoreWordsAdvance.length < 12));
            }
        } catch (error) {

        }
    };

    const handleSelectType = (usage) => {
        setOpen(false)
        // navigation.navigate('MatchingScreen', { type: type, usage: usage })
        navigate('/eslestirme-oyunu')
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TopMenuLayout _currentpage={'egzersizler'} />
            <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: mycolors.backGroundColor }}>


                <div style={{ flex: 2, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ flex: 1, width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column' }}>
                        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', padding: 10 }}>
                            <div style={{ marginTop: 20, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: 10, border: `1px solid ${mycolors.generalColor}`, overflow: 'hidden' }}>
                                <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                                        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column' }}>
                                            <span style={{ color: mycolors.white, fontSize: 17, fontWeight: '700' }}>{resources.MatchGame}</span>
                                            <img src='/assets/images/games-images/match-icon.png' style={{ height: 55, width: 50 }} />
                                        </div>
                                    </div>
                                    <div style={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                                        <div disabled={(hideBeginner && hideIntermediate && hideAdvance)} onClick={() => { setType('memorize'); setOpen(true) }} style={{ height: 30, marginRight: 10, display: 'flex', flexDirection: 'row', cursor: 'pointer', opacity: (hideBeginner && hideIntermediate && hideAdvance) ? 0.5 : 1 }}>
                                            <img src='/assets/images/words-images/brain.png' style={{ height: 30, width: 35, marginRight: 5 }} />
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <span style={{ color: mycolors.white, fontWeight: '600', paddingLeft: 5 }}>{resources.AmongLearnt}</span>
                                            </div>
                                        </div>
                                        <div onClick={() => { setType('random'); setOpen(true) }} style={{ cursor: 'pointer', display: 'flex', height: 30, marginRight: 10, flexDirection: 'row' }}>
                                            <img src='/assets/images/settings-icon.png' style={{ height: 30, width: 35, marginRight: 5 }} />

                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <span style={{ color: mycolors.white, fontWeight: '600' }}>{resources.Random}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: open ? 'flex' : 'none' }}>

                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div disabled={(type == 'memorize' && hideBeginner)} onClick={() => {
                                            handleSelectType(1)
                                        }} style={{ cursor: 'pointer', height: 50, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', borderRadius: 10, borderWidth: 1, borderColor: mycolors.generalColor, opacity: (type == 'memorize' && hideBeginner) ? 0.5 : 1 }}>
                                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50 }}>
                                                <img src='../assets/images/words-images/crown1.png' style={{ height: 30, width: 30 }} />
                                            </div>
                                            <span style={{ fontSize: 15, fontWeight: '600', color: mycolors.generalColor }}>
                                                {resources.BeginnerWords}
                                            </span>
                                        </div>
                                    </div>

                                    <div style={{ cursor: 'pointer', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
                                        <div disabled={(type == 'memorize' && hideIntermediate)} onClick={() => {
                                            handleSelectType(2)
                                        }} style={{ height: 50, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', borderRadius: 10, borderWidth: 1, borderColor: mycolors.generalColor, opacity: (type == 'memorize' && hideIntermediate) ? 0.5 : 1 }}>
                                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50 }}>

                                                <img src='../assets/images/words-images/crown2.png' style={{ height: 30, width: 30 }} />
                                            </div>
                                            <span style={{ fontSize: 15, fontWeight: '600', color: mycolors.generalColor }}>
                                                {resources.IntermediateWords}
                                            </span>
                                        </div>
                                    </div>

                                    <div style={{ cursor: 'pointer', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div disabled={(type == 'memorize' && hideAdvance)} onClick={() => {
                                            handleSelectType(3)
                                        }} style={{ height: 50, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', borderRadius: 10, borderWidth: 1, borderColor: mycolors.generalColor, opacity: (type == 'memorize' && hideAdvance) ? 0.5 : 1 }}>
                                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50 }}>
                                                <img src='../assets/images/words-images/crown3.png' style={{ height: 30, width: 30 }} />
                                            </div>
                                            <span style={{ fontSize: 15, fontWeight: '600', color: mycolors.generalColor }}>
                                                {resources.AdvanceWords}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ flex: 1, width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row' }}>
                    </div>

                    <div style={{ flex: 1, width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row' }}>
                    </div>
                </div>

                <div style={{ flex: 1 }}></div>
            </div>
        </div>
    );
}


export default ExercisesScreen;

