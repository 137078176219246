import React, { useEffect, useState } from "react";
import { mycolors } from "../helpers/mycolors";
import { resources } from "../helpers/resources";
import { postMethod, urls } from "../utils/api";
import { useNavigate } from 'react-router-dom';
import Loading from "../components/Loading";
import TopMenuLayout from "../components/TopMenuLayout";

const WordScreen = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [doneWordIds, setDoneWordIds] = useState([]);
    const [eliminatedWordIds, setEliminatedWordIds] = useState([]);
    const [beginnerProgress, setBeginnerProgress] = useState('');
    const [intermediateProgress, setIntermediateProgress] = useState('');
    const [advanceProgress, setAdvanceProgress] = useState('');
    const [progressBeginner, setProgressBeginner] = useState(0);
    const [progressIntermediate, setProgressIntermediate] = useState(0);
    const [progressAdvance, setProgressAdvance] = useState(0);
    const [open, setOpen] = useState(false);
    const [chosenUsage, setChosenUsage] = useState(0);
    const [hideRepeatButtonBeginner, setHideRepeatButtonBeginner] = useState(false);
    const [hideRepeatButtonIntermediate, setHideRepeatButtonIntermediate] = useState(false);
    const [hideRepeatButtonAdvance, setHideRepeatButtonAdvance] = useState(false);
    const [hideBeginnerLearningButton, setHideBeginnerLearningButton] = useState(false);
    const [hideIntermediateLearningButton, setHideIntermediateLearningButton] = useState(false);
    const [hideAdvanceLearningButton, setHideAdvanceLearningButton] = useState(false);

    const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        getDoneWordIds();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getDoneWordIds = async () => {
        try {

            //await localStorage.setItem('doneWordIds', '[]');

            const _doneWordIds = localStorage.getItem('doneWordIds');

            if (_doneWordIds == null || _doneWordIds == undefined) {
                localStorage.setItem('doneWordIds', '[]');
                setDoneWordIds([]);
                getWordsCount([]);
                setHideRepeatButtonBeginner(true);
                setHideRepeatButtonIntermediate(true);
                setHideRepeatButtonAdvance(true);
            }
            else {
                const storedList = JSON.parse(_doneWordIds);

                const eliminateIgnoreWords = storedList.filter(x => x.type != 'ignore');
                const eliminateIgnoreWordsBeginner = storedList.filter(x => x.type != 'ignore' && x.usage == 1);
                const eliminateIgnoreWordsIntermediate = storedList.filter(x => x.type != 'ignore' && x.usage == 2);
                const eliminateIgnoreWordsAdvance = storedList.filter(x => x.type != 'ignore' && x.usage == 3);

                setEliminatedWordIds(eliminateIgnoreWords);
                setHideRepeatButtonBeginner(eliminateIgnoreWordsBeginner.length == 0);
                setHideRepeatButtonIntermediate(eliminateIgnoreWordsIntermediate.length == 0);
                setHideRepeatButtonAdvance(eliminateIgnoreWordsAdvance.length == 0);
                setDoneWordIds(storedList);
                getWordsCount(storedList);
            }
        } catch (error) {

        }
    };

    const getWordsCount = async (_doneWordIds) => {
        try {
            const response = await postMethod(urls.get_words_count, null);

            const beginnerCount = _doneWordIds.filter(item => item.usage === 1).length;
            const intermediateCount = _doneWordIds.filter(item => item.usage === 2).length;
            const advanceCount = _doneWordIds.filter(item => item.usage === 3).length;

            setBeginnerProgress(`${beginnerCount} / ${response.beginnerCount}`);
            setIntermediateProgress(`${intermediateCount} / ${response.intermediateCount}`);
            setAdvanceProgress(`${advanceCount} / ${response.advanceCount}`);

            setHideBeginnerLearningButton(beginnerCount == response.beginnerCount);
            setHideIntermediateLearningButton(intermediateCount == response.intermediateCount);
            setHideAdvanceLearningButton(advanceCount == response.advanceCount);

            setTimeout(() => { setProgressBeginner(beginnerCount / response.beginnerCount); }, 500);
            setTimeout(() => { setProgressIntermediate(intermediateCount / response.intermediateCount); }, 500);
            setTimeout(() => { setProgressAdvance(advanceCount / response.advanceCount); }, 500);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>

        <TopMenuLayout _currentpage={'seviyelerine-gore-ingilizce-kelimeler'} />
        <div style={{ width: '100%', height: '100vh', backgroundColor: mycolors.backGroundColor, display: 'flex', justifyContent: isMobileScreen ? 'flex-start' : 'center', alignItems: isMobileScreen ? 'center' : 'flex-start', flexDirection: isMobileScreen ? 'column' : 'row' }}>

            <div style={{ width: '90%', display: 'flex', justifyContent: 'space-around', marginTop: '20px', flexDirection: isMobileScreen ? 'column' : 'row' }}>
                <div style={{ width: isMobileScreen ? '100%' : '30%', height: 200, border: `1px solid ${mycolors.generalColor}`, borderRadius: 5, display: 'flex', flexDirection: 'column', margin: '10px 0' }}>
                    <div style={{ height: 80, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='/assets/images/words-images/crown1.png' height={40} style={{ cursor: 'pointer' }} />
                        </div>
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <span style={{ color: mycolors.white, fontSize: 17, fontWeight: '700' }}>{resources.BeginnerWords}</span>
                            <span style={{ color: mycolors.white, fontSize: 15, fontWeight: '700' }}>{beginnerProgress}</span>
                        </div>
                    </div>

                    <div style={{ height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <progress width={'90%'} value={progressBeginner} />
                    </div>

                    <div style={{ height: 70, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-around' }}>
                        <div disabled={hideBeginnerLearningButton} onClick={() => { navigate('/ingilizce-kelime-ezberle', { state: { usage: 1 } }); }} style={{ cursor: 'pointer', display: 'flex', height: 30, marginRight: 10, flexDirection: 'row', opacity: hideBeginnerLearningButton ? 0.5 : 1 }}>
                            <img src='/assets/images/settings-icon.png' style={{ height: 30, width: 30 }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: mycolors.white, fontWeight: '600' }}>{hideRepeatButtonBeginner ? resources.StartToLearning : resources.KeepLearning}</span>
                            </div>
                        </div>
                        <div disabled={hideRepeatButtonBeginner} onClick={() => { navigate('/tekrar-et', { state: { usage: 1 } }); }} style={{ cursor: 'pointer', display: 'flex', height: 30, marginRight: 10, flexDirection: 'row', opacity: hideRepeatButtonBeginner ? 0.4 : 1 }}>
                            <img src='/assets/images/words-images/brain.png' style={{ height: 30, width: 30, marginRight: 5 }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: mycolors.white, fontWeight: '600' }}>{resources.Repeat}</span>
                            </div>
                        </div>
                    </div>

                </div>



                <div style={{ width: isMobileScreen ? '100%' : '30%', height: 200, border: `1px solid ${mycolors.generalColor}`, borderRadius: 5, display: 'flex', flexDirection: 'column', margin: '10px 0' }}>
                    <div style={{ height: 80, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='/assets/images/words-images/crown2.png' height={40} style={{ cursor: 'pointer' }} />
                        </div>
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <span style={{ color: mycolors.white, fontSize: 17, fontWeight: '700' }}>{resources.IntermediateWords}</span>
                            <span style={{ color: mycolors.white, fontSize: 15, fontWeight: '700' }}>{intermediateProgress}</span>
                        </div>
                    </div>

                    <div style={{ height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <progress width={'90%'} value={progressIntermediate} />
                    </div>

                    <div style={{ height: 70, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-around' }}>
                        <div disabled={hideIntermediateLearningButton} onClick={() => { navigate('/ingilizce-kelime-ezberle', { state: { usage: 2 } }); }} style={{ cursor: 'pointer', display: 'flex', height: 30, marginRight: 10, flexDirection: 'row', opacity: hideBeginnerLearningButton ? 0.5 : 1 }}>
                            <img src='/assets/images/settings-icon.png' style={{ height: 30, width: 30 }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: mycolors.white, fontWeight: '600' }}>{hideRepeatButtonIntermediate ? resources.StartToLearning : resources.KeepLearning}</span>
                            </div>
                        </div>
                        <div disabled={hideRepeatButtonIntermediate} onClick={() => { navigate('/tekrar-et', { state: { usage: 2 } }); }} style={{ cursor: 'pointer', display: 'flex', height: 30, marginRight: 10, flexDirection: 'row', opacity: hideRepeatButtonBeginner ? 0.4 : 1 }}>
                            <img src='/assets/images/words-images/brain.png' style={{ height: 30, width: 30, marginRight: 5 }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: mycolors.white, fontWeight: '600' }}>{resources.Repeat}</span>
                            </div>
                        </div>
                    </div>

                </div>



                <div style={{ width: isMobileScreen ? '100%' : '30%', height: 200, border: `1px solid ${mycolors.generalColor}`, borderRadius: 5, display: 'flex', flexDirection: 'column', margin: '10px 0' }}>
                    <div style={{ height: 80, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='/assets/images/words-images/crown2.png' height={40} style={{ cursor: 'pointer' }} />
                        </div>
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <span style={{ color: mycolors.white, fontSize: 17, fontWeight: '700' }}>{resources.AdvanceWords}</span>
                            <span style={{ color: mycolors.white, fontSize: 15, fontWeight: '700' }}>{advanceProgress}</span>
                        </div>
                    </div>

                    <div style={{ height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <progress width={'90%'} value={progressAdvance} />
                    </div>

                    <div style={{ height: 70, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-around' }}>
                        <div disabled={hideAdvanceLearningButton} onClick={() => { navigate('/ingilizce-kelime-ezberle', { state: { usage: 3 } }); }} style={{ cursor: 'pointer', display: 'flex', height: 30, marginRight: 10, flexDirection: 'row', opacity: hideBeginnerLearningButton ? 0.5 : 1 }}>
                            <img src='/assets/images/settings-icon.png' style={{ height: 30, width: 30 }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: mycolors.white, fontWeight: '600' }}>{hideRepeatButtonAdvance ? resources.StartToLearning : resources.KeepLearning}</span>
                            </div>
                        </div>
                        <div disabled={hideRepeatButtonAdvance} onClick={() => { navigate('/tekrar-et', { state: { usage: 3 } }); }} style={{ cursor: 'pointer', display: 'flex', height: 30, marginRight: 10, flexDirection: 'row', opacity: hideRepeatButtonBeginner ? 0.4 : 1 }}>
                            <img src='/assets/images/words-images/brain.png' style={{ height: 30, width: 30, marginRight: 5 }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: mycolors.white, fontWeight: '600' }}>{resources.Repeat}</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        </div>
    );
};

export default WordScreen;