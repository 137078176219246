import React, { useEffect, useState } from 'react';
import { mycolors } from '../helpers/mycolors';
import { resources } from '../helpers/resources';
import { urls } from '../utils/api';

const QuizMulti = ({ dataParam, handleNextButton, handlePreviousButton, currentIndex, listLength }) => {

    const [answerStatus, setAnswerStatus] = useState('show-default');
    const [data, setData] = useState(dataParam);
    const [isButtonValid, setIsButtonValid] = useState(false);

    // const handleDataUpdate = (newData: any) => {
    //     let _data = [...data];
    //     let index = _data.findIndex(x => x.id == newData.id);
    //     _data[index] = newData;
    //     //setData(prev => _data);
    // }

    const handleClickAnswer = (item) => {
        try {
            let _data = JSON.parse(JSON.stringify(data));
            let _userAnswers = [...data.userAnswers];

            const answerLength = _userAnswers.length;
            const index = _userAnswers.indexOf(item.id);

            if (index === -1) {
                _userAnswers.push(item.id);
                _data.userAnswers = _userAnswers;

                let updatedAnswers = [...data.answers];
                updatedAnswers[item.index].selected = true;
                _data.answers = updatedAnswers;

                //handleDataUpdate(_data);
                setData(_data);

                if ((answerLength + 1) == data.correctAnswerCount) {

                    var everyAnswerCorrect = true;

                    _userAnswers.forEach(usrAns => {
                        const _item = data.answers.find(x => x.id == usrAns);
                        if (!_item.isAnswer) {
                            everyAnswerCorrect = false;
                        }
                    });

                    if (everyAnswerCorrect) {
                        setAnswerStatus('show-correct');
                    } else {
                        setAnswerStatus('show-incorrect');
                    }

                    setIsButtonValid(true);

                    // setTimeout(() => {
                    //     setAnswerStatus('show-default');
                    //     // handleNext({
                    //     //     item: data,
                    //     //     isCorrect: everyAnswerCorrect
                    //     // });
                    // }, 1000);
                }
            }
            else {
                _userAnswers.splice(index, 1);
                _data.userAnswers = _userAnswers;

                let updatedAnswers = [...data.answers];
                updatedAnswers[item.index].selected = false;
                _data.answers = updatedAnswers;
                //handleDataUpdate(_data);
                setData(_data);

            }
        }
        catch (e) {
            //console.log(e)
        }
    }

    const renderAnswers = () => {

        const style0 = data.answers[0].selected ? styles.selectedContainer : styles.answerContainer;
        const style1 = data.answers[1].selected ? styles.selectedContainer : styles.answerContainer;
        const style2 = data.answers[2].selected ? styles.selectedContainer : styles.answerContainer;
        const style3 = data.answers[3].selected ? styles.selectedContainer : styles.answerContainer;
        const style4 = data.answers[4].selected ? styles.selectedContainer : styles.answerContainer;
        const style5 = data.answers[5].selected ? styles.selectedContainer : styles.answerContainer;

        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div style={style0} onClick={() => handleClickAnswer(data.answers[0])}>
                    <span style={styles.answerText}>{data.answers[0].text}</span>
                </div>
                <div style={style1} onClick={() => handleClickAnswer(data.answers[1])}>
                    <span style={styles.answerText}>{data.answers[1].text}</span>
                </div>
                <div style={style2} onClick={() => handleClickAnswer(data.answers[2])}>
                    <span style={styles.answerText}>{data.answers[2].text}</span>
                </div>
                <div style={style3} onClick={() => handleClickAnswer(data.answers[3])}>
                    <span style={styles.answerText}>{data.answers[3].text}</span>
                </div>
                <div style={style4} onClick={() => handleClickAnswer(data.answers[4])}>
                    <span style={styles.answerText}>{data.answers[4].text}</span>
                </div>
                <div style={style5} onClick={() => handleClickAnswer(data.answers[5])}>
                    <span style={styles.answerText}>{data.answers[5].text}</span>
                </div>
            </div>
        )
    }

    const renderCorrectAnswer = () => {
        const style0 = data.answers[0].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;
        const style1 = data.answers[1].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;
        const style2 = data.answers[2].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;
        const style3 = data.answers[3].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;
        const style4 = data.answers[4].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;
        const style5 = data.answers[5].isAnswer ? styles.correctAnswerContainer : styles.answerContainer;


        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div style={style0}>
                    <span style={styles.answerText}>{data.answers[0].text}</span>
                </div>
                <div style={style1}>
                    <span style={styles.answerText}>{data.answers[1].text}</span>
                </div>
                <div style={style2}>
                    <span style={styles.answerText}>{data.answers[2].text}</span>
                </div>
                <div style={style3}>
                    <span style={styles.answerText}>{data.answers[3].text}</span>
                </div>
                <div style={style4}>
                    <span style={styles.answerText}>{data.answers[4].text}</span>
                </div>
                <div style={style5}>
                    <span style={styles.answerText}>{data.answers[5].text}</span>
                </div>
            </div>
        )
    }

    const renderIncorrectAnswer = () => {
        const style0 = data.answers[0].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;
        const style1 = data.answers[1].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;
        const style2 = data.answers[2].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;
        const style3 = data.answers[3].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;
        const style4 = data.answers[4].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;
        const style5 = data.answers[5].isAnswer ? styles.correctAnswerContainer : styles.incorrectAnswerContainer;

        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div style={style0}>
                    <span style={styles.answerText}>{data.answers[0].text}</span>
                </div>
                <div style={style1}>
                    <span style={styles.answerText}>{data.answers[1].text}</span>
                </div>
                <div style={style2}>
                    <span style={styles.answerText}>{data.answers[2].text}</span>
                </div>
                <div style={style3}>
                    <span style={styles.answerText}>{data.answers[3].text}</span>
                </div>
                <div style={style4}>
                    <span style={styles.answerText}>{data.answers[4].text}</span>
                </div>
                <div style={style5}>
                    <span style={styles.answerText}>{data.answers[5].text}</span>
                </div>
            </div>
        )
    }

    const getAudio = async (word) => {
        try {
            const audioUrl = urls.api_url + urls.get_audio.replace('{soundKey}', word);
            const audio = new Audio(audioUrl);

            audio.play().catch((error) => {
                //console.error('Audio playback failed:', error);
            });
        } catch (error) {
            //console.error('Error in getAudio:', error);
        }
    };



    return (
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
            <div style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

                <div style={{ height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', height: 20, width: '100%' }}>

                    </div>
                    <div style={{ height: '50%', width: '90%', justifyContent: 'center', alignItems: 'center', borderRadius: 10, border: `2px solid ${mycolors.generalColor}` }}>
                        <div style={{ height: '100%', width: '100%', borderRadius: 10, overflow: 'hidden' }}>
                            <div style={{ height: 40, width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 40, display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div onClick={() => { getAudio(data.word) }} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <img src='../assets/images/grammar-images/word-sound-icon.png' style={{ height: 35, width: 35 }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={styles.wordText}>{data.word}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', margin:'10px 0px', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: mycolors.generalColor, fontSize: 14, marginVertical: 5, textAlign: 'center' }}>{resources.QuizMultiInfoMessage.replace('{count}', dataParam.correctAnswerCount)}</span>
                    </div>
                    <div style={{ height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                        <div style={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', borderRadius: 10, overflow: 'hidden' }}>
                            <div style={{ height: '100%', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    answerStatus == 'show-default'
                                        ? renderAnswers()
                                        : answerStatus == 'show-correct'
                                            ? renderCorrectAnswer()
                                            : answerStatus == 'show-incorrect'
                                                ? renderIncorrectAnswer()
                                                : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 60, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {
                    isButtonValid
                        ?
                        <div style={{ height: 60, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>

                            {/* {
                                currentIndex === 0
                                    ? <div style={{ height: 50, width: 100 }}></div>
                                    : <div onClick={() => { handlePreviousButton() }} style={{ height: 50, width: 100, backgroundColor: mycolors.generalColor, borderWidth: 1, borderColor: mycolors.generalColor, marginRight: 10, borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
                                        <span style={{ color: mycolors.black, fontWeight: '600', fontSize: 16 }}>{resources.Previous}</span>
                                    </div>
                            } */}

                            <div disabled={!isButtonValid} onClick={() => { handleNextButton() }} style={{ opacity: isButtonValid ? 1 : 0.5, height: 50, width: 100, backgroundColor: mycolors.generalColor, borderWidth: 1, borderColor: mycolors.generalColor, marginRight: 10, borderRadius: 10, alignItems: 'center', justifyContent: 'center', display:'flex' }}>
                                <span style={{ color: mycolors.black, fontWeight: '600', fontSize: 16 }}>{currentIndex === listLength ? resources.Finish : resources.Next}</span>
                            </div>
                        </div>
                        : <></>
                }
            </div>

        </div>

    );
}

export default QuizMulti;


const styles = {

    wordText: {
        color: mycolors.generalColor,
        fontWeight: '700',
        fontSize: 23,
        textAlign: 'center',
        marginBottom: 40
    },

    answerContainer: {
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        width: '95%',
        margin: '5px 0px',
        backgroundColor: mycolors.backGroundColor,
        borderRadius: 10,
        border: `2px solid ${mycolors.generalColor}`,
        alignItems: 'center',
        justifyContent: 'center'
    },

    selectedContainer: {
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        width: '95%',
        margin: '5px 0px',
        backgroundColor: '#72A0C1',
        borderRadius: 10,
        borderWidth: 2,
        border: `2px solid #72A0C1`,
        alignItems: 'center',
        justifyContent: 'center'
    },

    correctAnswerContainer: {
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        width: '95%',
        margin: '5px 0px',
        backgroundColor: mycolors.correctGreen, //'#00A67E',
        borderRadius: 10,
        borderWidth: 2,
        border: `2px solid ${mycolors.correctGreen}`, //'#00A67E',
        alignItems: 'center',
        justifyContent: 'center'
    },

    incorrectAnswerContainer: {
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        width: '95%',
        margin: '5px 0px',
        backgroundColor: mycolors.invalidRed, //'#171620',
        borderRadius: 10,
        borderWidth: 2,
        border: `2px solid ${mycolors.invalidRed}`, //'#FF4500',
        alignItems: 'center',
        justifyContent: 'center'
    },

    answerText: {
        fontSize: 16,
        color: 'white',
        fontWeight: '500',
        textAlign: 'center'
    }

};
