import { useEffect, useState } from "react";
import { mycolors } from "../helpers/mycolors";

const MobileCopyRight = () => {

    return (
        <div style={{ width: '100%', minHeight:'100vh', backgroundColor: mycolors.backGroundColor, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div
                style={{
                    fontFamily: "Arial, sans-serif",
                    lineHeight: "1.6",
                    margin: "20px",
                    padding: "0",
                    color: mycolors.white,
                }}
            >
                <h1 style={{ color: mycolors.generalColor }}>Uygulama Telif Hakkı ve Gizlilik Politikası</h1>

                <h2 style={{ color: mycolors.generalColor }}>Telif Hakkı</h2>
                <p>
                    Bu uygulama ve içeriği telif hakkı koruması altındadır. © 2025 Ibrahim BAVLI. Tüm hakları saklıdır.
                    Uygulamanın içeriği, tasarımı ve işlevselliği sadece bireysel kullanım içindir ve hiçbir şekilde
                    izinsiz kopyalanamaz, çoğaltılamaz veya dağıtılamaz.
                </p>

                <h2 style={{ color: mycolors.generalColor }}>Gizlilik Politikası</h2>
                <p>
                    Bu uygulama herhangi bir kişisel veri toplamaz veya işlemez. Kullanıcıların gizliliği tamamen korunmaktadır. Sadece kullanici kendisi isterse sisteme uye olabilir ve bu esnada, email bilgisi alinir. Fakat bu email bilgisi direkt olarak hicbir sirketle dogrudan kendi sistemimiz tarafindan paylasilmaz.
                </p>
                <p>
                    Uygulama yalnızca üçüncü taraf reklamları göstermektedir. Bu reklamlarla ilgili veri işlemleri,
                    reklam sağlayıcının gizlilik politikalarına tabidir. Reklamların yönetimi ve kullanımı ile ilgili
                    daha fazla bilgi için lütfen ilgili reklam sağlayıcının gizlilik politikalarını inceleyin.
                </p>

                <h2 style={{ color: mycolors.generalColor }}>Sorumluluk Reddi</h2>
                <p>
                    Bu uygulama, İngilizce kelime öğrenimi için yardımcı bir araç olarak tasarlanmıştır.
                    Sağlanan içeriğin doğruluğu ve uygunluğu konusunda her türlü özen gösterilmiştir ancak
                    içeriğin kullanımı tamamen kullanıcı sorumluluğundadır.
                </p>

                <h2 style={{ color: mycolors.generalColor }}>İletişim</h2>
                <p>
                    Sorularınız veya geri bildirimleriniz için lütfen benimle iletişime geçin:{" "}
                    <a
                        href="mailto:ibrahimbavli10@gmail.com"
                        style={{ color: "#0066cc", textDecoration: "none" }}
                        onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                        onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                    >
                        ibrahimbavli10@gmail.com
                    </a>
                </p>
            </div>

        </div>
    );
}

export default MobileCopyRight;
