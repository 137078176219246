import { useEffect, useState } from "react";
import { mycolors } from "../helpers/mycolors";
import { useNavigate } from 'react-router-dom';
import TopMenuLayout from "../components/TopMenuLayout";

const Homepage = () => {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1500);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1500);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    //web view
    if (!isSmallScreen) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TopMenuLayout _currentpage={'homepage'} />
                <div style={{ width: '100%', backgroundColor: mycolors.backGroundColor, display: 'flex', flexDirection: 'column' }}>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <h1 style={{ color: mycolors.generalColor }}>Ingilizce kelime öğrenmenin en iyi yöntemi</h1>
                        <h2 style={{ color: mycolors.white }}>Ingilizce kelime bilginizi, anlamlarini gorerek, telaffuzunu dinleyerek ve oyunlarla tekrar ederek eglenceli bir sekilde ogrenmek ister misiniz?</h2>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div onClick={() => { navigate('/seviyelerine-gore-ingilizce-kelimeler') }} style={{ cursor: 'pointer', padding: '0px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 45, width: 'fit-content', border: `1px solid ${mycolors.generalColor}`, borderRadius: 10, backgroundColor: mycolors.generalColor, color: mycolors.black, fontWeight: '600' }}>Webten öğrenmeye başlamak için tıklayın</div>
                    </div>

                    <p style={{ textAlign: 'center' }}>
                    </p>


                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='/assets/appstore.png'  onClick={() => { window.open('https://apps.apple.com/us/app/ingilizce-kelime-ogren/id6740737628', '_blank')}} alt="appstore logo" height={40} style={{ cursor: 'pointer' }} />
                        </div>
                        <div onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.teacherparrot.ingilizcekelimeogren', '_blank'); }} style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='/assets/googleplay.png' alt="google play logo" height={40} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='/assets/ios-1.png' alt="ios screenshot web 1" height={500} />
                            <img src='/assets/ios-2.png' alt="ios screenshot web 1" height={500} />
                            <img src='/assets/ios-3.png' alt="ios screenshot web 1" height={500} />
                        </div>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src='/assets/android-1.png' alt="android screenshot web 1" height={530} />
                            <img src='/assets/android-2.png' alt="android screenshot web 1" height={530} />
                            <img src='/assets/android-3.png' alt="android screenshot web 1" height={530} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', width: '60%', flexDirection: 'column' }}>
                            <p style={{ fontSize: 22, color: mycolors.white, textAlign: 'center' }}>
                                Ingilizce kelime öğrenmenin en önemli yöntemlerinden biri bol bol tekrar etmektir. Yeni bir Ingilizce kelime öğrenirken diger en önemli nokta ise telaffuzunu dogru öğrenmektir. Bu uygulama ile tamamen ücretsiz bir sekilde Ingilizce kelime haznenizi geliştirebilirsiniz. Uygulamada kelimeler kullanım sıklıklarına gore ayrılmştır. Bu seviyeler;
                            </p>
                            <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>Baslangıç seviye Ingilizce kelimeler</span>
                            <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>Orta seviye Ingilizce kelimeler</span>
                            <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>Ileri seviye Ingilizce kelimeler</span>
                            <p style={{ fontSize: 22, color: mycolors.white, textAlign: 'center' }}>
                                Her kelimenin anlamlarını ve telaffuzlarını öğrenebilirsiniz. Dilediğiniz zaman öğrenmis olduğunuz bu kelimeleri tekrar edebilirsiniz.
                            </p>
                            <p style={{ fontSize: 22, color: mycolors.white, textAlign: 'center' }}>
                                Ingilizce Kelime Öğren uygulaması ile İngilizce kelimeleri kolayca öğrenin ve gelişen kelime bilginizle daha etkili iletişim kurun! Bu tamamen ücretsiz uygulama, kelimelerin anlamlarını öğrenmenin yanı sıra telaffuzlarını dinleyebilmenizi ve ezberlemenizi sağlar. Eşleştirme oyunu özelliği ile kelimeleri eşleştirme oyunu oynayarak ve eğlenerek Ingilizce kelime öğrenebilirsiniz.
                            </p>
                            <p style={{ fontSize: 22, color: mycolors.white, textAlign: 'center' }}>
                                Her kelime grubunda ne kadar ezber yaptığınızı özet sayfasında kolayca görüntüleyebilirsiniz. Öne çıkan özellikler:
                            </p>
                            <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>📚 Geniş kelime hazinesi</span>
                            <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>🎮 Eğlenceli oyun modları</span>
                            <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>🌙 Karanlık tema desteği</span>
                            <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>🔔 Günlük hatırlatmalar</span>
                        </div>
                    </div>



                </div>
            </div>
        )
    }


    return (

        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TopMenuLayout _currentpage={'homepage'} />
            <div style={{ width: '100%', backgroundColor: mycolors.backGroundColor, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                <div style={{ textAlign: 'center', width: '80%' }}>
                    <h1 style={{ color: mycolors.generalColor }}>Ingilizce kelime öğrenmenin en iyi yöntemi</h1>
                    <h2 style={{ color: mycolors.white }}>Ingilizce kelime bilginizi, anlamlarini görerek, telaffuzunu dinleyerek ve oyunlarla tekrar ederek eğlenceli bir sekilde öğrenmek ister misiniz?</h2>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div onClick={() => { navigate('/seviyelerine-gore-ingilizce-kelimeler') }} style={{ cursor: 'pointer', padding: '0px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 45, width: 'fit-content', border: `1px solid ${mycolors.generalColor}`, borderRadius: 10, backgroundColor: mycolors.generalColor, color: mycolors.black, fontWeight: '600' }}>Webten öğrenmeye başlamak için tıklayın</div>
                </div>

                <p style={{ textAlign: 'center' }}>
                </p>

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div onClick={() => { window.open('https://apps.apple.com/us/app/ingilizce-kelime-ogren/id6740737628', '_blank')}} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src='/assets/appstore.png' alt="app store logo mobile" height={40} style={{ cursor: 'pointer' }} />
                    </div>
                    <div onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.teacherparrot.ingilizcekelimeogren', '_blank'); }} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src='/assets/googleplay.png' alt="google play logo mobile" height={40} style={{ cursor: 'pointer' }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <img src='/assets/ios-1.png' alt="ios screenshot 1" height={500} />
                        <img src='/assets/ios-2.png' alt="ios screenshot 2" height={500} />
                        <img src='/assets/ios-3.png' alt="ios screenshot 3" height={500} />
                    </div>
                </div>

                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', width: '60%', flexDirection: 'column' }}>
                        <p style={{ fontSize: 22, color: mycolors.white, textAlign: 'center' }}>
                            Ingilizce kelime öğrenmenin en önemli yöntemlerinden biri bol bol tekrar etmektir. Yeni bir Ingilizce kelime öğrenirken diger en önemli nokta ise telaffuzunu dogru öğrenmektir. Bu uygulama ile tamamen ücretsiz bir sekilde Ingilizce kelime haznenizi geliştirebilirsiniz. Uygulamada kelimeler kullanım sıklıklarına gore ayrılmştır. Bu seviyeler;
                        </p>
                        <h2 style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>Baslangiç seviye Ingilizce kelimeler</h2>
                        <h2 style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>Orta seviye Ingilizce kelimeler</h2>
                        <h2 style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>Ileri seviye Ingilizce kelimeler</h2>
                        <p style={{ fontSize: 22, color: mycolors.white, textAlign: 'center' }}>
                            Her kelimenin anlamlarını ve telaffuzlarını öğrenebilirsiniz. Dilediğiniz zaman öğrenmis olduğunuz bu kelimeleri tekrar edebilirsiniz.
                        </p>
                        <p style={{ fontSize: 22, color: mycolors.white, textAlign: 'center' }}>
                            Ingilizce Kelime Öğren uygulaması ile İngilizce kelimeleri kolayca öğrenin ve gelişen kelime bilginizle daha etkili iletişim kurun! Bu tamamen ücretsiz uygulama, kelimelerin anlamlarını öğrenmenin yanı sıra telaffuzlarını dinleyebilmenizi ve ezberlemenizi sağlar. Eşleştirme oyunu özelliği ile kelimeleri eşleştirme oyunu oynayarak ve eğlenerek Ingilizce kelime öğrenebilirsiniz.
                        </p>
                        <p style={{ fontSize: 22, color: mycolors.white, textAlign: 'center' }}>
                            Her kelime grubunda ne kadar ezber yaptığınızı özet sayfasında kolayca görüntüleyebilirsiniz. Öne çıkan özellikler:
                        </p>
                        <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>📚 Geniş kelime hazinesi</span>
                        <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>🎮 Eğlenceli oyun modları</span>
                        <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>🌙 Karanlık tema desteği</span>
                        <span style={{ fontSize: 22, color: mycolors.generalColor, textAlign: 'center' }}>🔔 Günlük hatırlatmalar</span>
                    </div>
                </div>



            </div>
        </div>

    );
}

export default Homepage;
